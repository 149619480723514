import Stock from "views/examples/Stock.jsx";
import Order from "views/examples/Order.jsx";
import Place_order from "views/examples/Place_order.jsx";
import Dashboard from "views/examples/Dashboard.jsx";
import page_not_found from "views/examples/page_not_found.jsx";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-compass-04 text-default",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/stock",
    name: "Stock",
    icon: "ni ni-cart text-red",
    component: Stock,
    layout: "/admin"
  },
  {
    path: "/order",
    name: "Order",
    icon: "ni ni-briefcase-24 text-default",
    component: Order,
    layout: "/admin"
  },
  {
    path: "/Place_order",
    name: "Place Order",
    icon: "ni ni-bag-17 text-default",
    component: Place_order,
    layout: "/admin"
  }
];
export default routes;
