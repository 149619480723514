import React from "react";
import "assets/css/transglobal.css";
import { Alert } from "reactstrap";

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Col, Modal,
} from "reactstrap";

import axios from 'axios';
import { server_config } from 'const';
import $ from "jquery";

// core components
import DashHeader from "components/Headers/dash_header.jsx";

class Place_order extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.onChangeCfname = this.onChangeCfname.bind(this);
    this.onChangeClname = this.onChangeClname.bind(this);
    this.onChangeCemail = this.onChangeCemail.bind(this);
    this.onChangeCmobile = this.onChangeCmobile.bind(this);
    this.onChangeCaddress = this.onChangeCaddress.bind(this);
    this.onChangeOrderProduct = this.onChangeOrderProduct.bind(this);
    this.onChangeCustomer = this.onChangeCustomer.bind(this);
    this.onChangeOrderDate = this.onChangeOrderDate.bind(this);
    this.onChangePaymentMethod = this.onChangePaymentMethod.bind(this);
    this.onChangeBrand = this.onChangeBrand.bind(this);
    this.onChangeProductType = this.onChangeProductType.bind(this);
    this.onChangeRemark = this.onChangeRemark.bind(this);


    this.onCloseAlert = this.onCloseAlert.bind(this);
    this.onCloseAlertQty = this.onCloseAlertQty.bind(this);
    this.onDiscountChange = this.onDiscountChange.bind(this);

    this.handleValidation = this.handleValidation.bind(this);

    this.savecustomer = this.savecustomer.bind(this);

    var date = new Date().getDate(); //Current Date
    var month1 = new Date().getMonth() + 1; //Current Month
    var month = (month1 < 10) ? "0" + month1 : month1;
    var year = new Date().getFullYear(); //Current Year

    this.state = {
      token: localStorage["appState"] ? JSON.parse(localStorage["appState"]).user.auth_token : "",
      u_id: localStorage["appState"] ? JSON.parse(localStorage["appState"]).user.id : "0",
      opened: true,
      show_total: false,
      show_alert: false,
      show_alert_qty: false,
      alert_msg: "Some error occurs.",
      customers: [],
      brands: [],
      product_types: [],
      products: [],
      c_fname: "",
      c_lname: "",
      c_email: "",
      c_mobile: "",
      c_address: "",
      o_customer_id: "",
      o_date: year + "-" + month + "-" + date,
      o_brand_id: "",
      o_product_type_id: "",
      o_product_id: "",
      o_sub_total: "0",
      o_discount: "",
      o_total: "0",
      o_payment_method: "COD",
      o_remark: "",
      errors: {},
      is_submit_click: 0,
      shareholders: [],
      customerDropdown: [],
    };
    this.addcustomer = this.addcustomer.bind(this);
    this.getFilterProductList = this.getFilterProductList.bind(this);
  }

  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["o_date"]) {
      formIsValid = false;
      errors["order_date"] = "Please enter Order Date.";
    }
  }
  checkValidation() {
    setTimeout(function () { //Start the timer
      if (this.state.is_submit_click === 1) {
        this.handleValidation();
      }
    }.bind(this), 300)
  }
  componentDidMount() {
    this._isMounted = true;
    axios.post(server_config.API_BASE_URL+"customer_list", [], { headers: { "Authorization": "Bearer " + this.state.token } })
      .then(result => {
        if (this._isMounted) {
          this.setState({
            customers: result.data.customer_data,
            o_customer_id: result.data.customer_data[0].customer_id,
          });
          result.data.customer_data.forEach(customer => {
            this.setState({
              customerDropdown: this.state.customerDropdown.concat([{ c_id: customer.customer_id, c_name: customer.full_name }])
            });
          });
        }
      }).catch(function (error) {
    });
    axios.post(server_config.API_BASE_URL+"brand_list", [], { headers: { "Authorization": "Bearer " + this.state.token } })
      .then(result => {
        if (this._isMounted) {
          this.setState({
            brands: result.data.brand_data,
            o_brand_id: result.data.brand_data[0].brand_id,
          });
        }
      }).catch(function (error) {
      });
    axios.post(server_config.API_BASE_URL+"product_type_list", [], { headers: { "Authorization": "Bearer " + this.state.token } })
      .then(result => {
        if (this._isMounted) {
          this.setState({
            product_types: result.data.product_type_data,
            o_product_type_id: result.data.product_type_data[0].product_type_id,
          });
        }
      }).catch(function (error) {
      });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  /*Order Form*/
  onChangeBrand(e) {
    this.setState({
      o_brand_id: e.target.value,
    });
    this.getFilterProductList(e.target.value, this.state.o_product_type_id);
  }
  onChangeProductType(e) {
    this.setState({
      o_product_type_id: e.target.value,
    });
    this.getFilterProductList(this.state.o_brand_id, e.target.value);
  }
  onChangeOrderProduct(e) {
    this.setState({
      o_product_id: e.target.value,
    });
  }
  onChangeCustomer(e) {
    this.setState({
      o_customer_id: e.target.value,
    });
  }
  onChangeOrderDate(e) {
    this.setState({
      o_date: e.target.value,
    });
    console.log(e.target.value);
    this.checkValidation();
  }
  onChangePaymentMethod(e) {
    this.setState({
      o_payment_method: e.target.value,
    });
  }
  onChangeRemark(e) {
    this.setState({
      o_remark: e.target.value,
    });
  }
  onDiscountChange(e) {
    var discount = (e.target.value !== '') ? e.target.value : 0;
    this.setState({
      o_discount: e.target.value,
      o_total: parseFloat(this.state.o_sub_total) - ((parseFloat(this.state.o_sub_total) * parseFloat(discount)) / 100).toFixed(2)
    });
  }
  onCloseAlert() {
    this.setState({
      show_alert: false,
    });
  }
  onCloseAlertQty() {
    this.setState({
      show_alert_qty: false,
    });
  }
  getFilterProductList(brand_id, p_type_id) {
    const filterData = {
      brand_id: brand_id,
      product_type_id: p_type_id,
    };
    axios.post(server_config.API_BASE_URL+"getFilterProductList", filterData, { headers: { "Authorization": "Bearer " + this.state.token } })
      .then(result => {
        if (result.data.response.status == 1) {
          this.setState({
            products: result.data.response.product,
            o_product_id: result.data.response.product[0].id,
          });
        } else {
          this.setState({
            products: [],
            o_product_id: 0,
          });
        }
      }).catch(function (error) {
      });
  }
  /*Order Form Complete*/

  /*Customer Form*/
  addcustomer() { //open Customer Modal
    this.setState({
      modal: !this.state.modal
    });
  }
  onChangeCfname(e) {
    this.setState({
      c_fname: e.target.value
    });
  }
  onChangeClname(e) {
    this.setState({
      c_lname: e.target.value
    });
  }
  onChangeCemail(e) {
    this.setState({
      c_email: e.target.value
    });
  }
  onChangeCmobile(e) {
    this.setState({
      c_mobile: e.target.value
    });
  }
  onChangeCaddress(e) {
    this.setState({
      c_address: e.target.value
    });
  }
  savecustomer() { //add customer
    const customerData = {
      fname: this.state.c_fname,
      lname: this.state.c_lname,
      email: this.state.c_email,
      mobile_no: this.state.c_mobile,
      address_line_1: this.state.c_address,
      user_id: this.state.u_id
    };
    axios.post(server_config.API_BASE_URL+"customer_insert", customerData, { headers: { "Authorization": "Bearer " + this.state.token } })
      .then(result => {
        this.setState({
          customerDropdown: this.state.customerDropdown.concat([{ c_id: result.data.customer_id, c_name: this.state.c_fname + " " + this.state.c_lname }])
        });
        this.setState({
          modal: !this.state.modal
        });
      }).catch(function (error) {
        alert(error);
      });
  }
  /*Customer Form Complete*/

  /*Order Product*/
  handleProductQtyChange = idx => evt => {
    const newShareholders = this.state.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      if (evt.target.value > shareholder.p_max_qty) {
        this.setState({
          alert_msg: "Only " + shareholder.p_max_qty + " quantity availble",
          show_alert_qty: true,
        });
        return { ...shareholder, o_qty: shareholder.o_qty, o_amount: shareholder.o_amount };
      } else {
        return { ...shareholder, o_qty: evt.target.value, o_amount: (shareholder.p_price * evt.target.value).toFixed(2) };
      }
    });
    var order_sub_total = 0;
    newShareholders.forEach(product => {
      order_sub_total += parseFloat(product.o_amount);
    });
    var discount = (this.state.o_discount !== '') ? this.state.o_discount : 0;
    this.setState({
      shareholders: newShareholders,
      o_sub_total: order_sub_total.toFixed(2),
      o_total: (parseFloat(order_sub_total) - ((parseFloat(order_sub_total) * parseFloat(discount)) / 100)).toFixed(2)
    });

  };
  handleSubmit = evt => {
    evt.preventDefault();
    this.setState({
      is_submit_click: 1
    });
    // console.log(this.state.is_add_click);
    const { shareholders } = this.state;
    if (shareholders.length <= 0) {
      this.setState({
        alert_msg: "Add Product For Order",
        show_alert: true,
      });
      window.scrollTo(0, 0);
    } else if (this.state.order_date === '' || parseFloat(this.state.sub_total) <= 0) {
      this.setState({
        alert_msg: "Please Fill All Field",
        show_alert: true,
      });
    } else {
        var order_product = [];
        this.state.shareholders.forEach(product => {
          if (product.p_id > 0) {
            var product_data = {};
            product_data["product_id"] = product.p_id;
            product_data["product_qty"] = product.o_qty;
            product_data["product_price"] = product.p_price;
            product_data["amount"] = product.o_amount;
            order_product.push(product_data);
          }
        });
        const orderData = {
          customer_id: this.state.o_customer_id,
          order_date: this.state.o_date,
          payment_type: this.state.o_payment_method,
          remarks: this.state.o_remark,
          sub_total_input: this.state.o_sub_total,
          discount: this.state.o_discount,
          total_order_input: this.state.o_total,
          products: order_product,
          user_id: this.state.u_id
        };
        axios.post(server_config.API_BASE_URL+"saveOrder", orderData, { headers: { "Authorization": "Bearer " + this.state.token } })
          .then(result => {
            alert('Order Placed Sucessfully');
            window.location.reload();
          }).catch(function (error) {
            alert(error);
          });
    }
  };

  appendProduct = () => {
    const productData = {
      product_id: this.state.o_product_id
    };
    axios.post(server_config.API_BASE_URL+"getProductById", productData, { headers: { "Authorization": "Bearer " + this.state.token } })
      .then(result => {
        this.setState({
          shareholders: this.state.shareholders.concat([{ p_id: result.data.product.id, p_name: result.data.product.name, p_price: result.data.product.sale_kwacha_price, p_max_qty: result.data.product.quantity, o_qty: "0", o_amount: "0" }]),
          show_total: true,
        });
      }).catch(function (error) {
        this.setState({
          alert_msg: "Product not found",
          show_alert: true,
        });
      });
  };

  removeProduct = idx => () => {
    this.setState({
      shareholders: this.state.shareholders.filter((s, sidx) => idx !== sidx)
    });
    const { shareholders } = this.state;
    console.log(shareholders.length);
    if (shareholders.length <= 1) {
      this.setState({
        show_total: false,
      });
    }
  };
  /*Order Product Complete*/

  render() {
    const formtitle = {
      fontSize: 20,
      fontWeight: "bold",
    };
    const btn_add = {
      padding: 0,
      lineHeight: 1,
      borderRadius: 25,
      fontSize: 14,
      width: 20,
      height: 20,
      fontWeight: 100,
    };
    const { opened, products, show_total, show_alert, show_alert_qty, brands, product_types } = this.state;
    return (
      <>
        <DashHeader />
        {/* Page content */}
        <Container style={{ marginBottom: 100 }} fluid>
          <Col lg="12" md="12" className="p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent pb-3">
                <div>
                  <p className="mb-0" style={formtitle}>Place Order</p>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                {show_alert && (
                  <Alert className="alert-warning">
                    <button type="button" className="close" onClick={this.onCloseAlert}>
                      <span aria-hidden="true">×</span>
                    </button>
                    <strong>Warning!</strong> {this.state.alert_msg}
                  </Alert>
                )}
                <form id="my-order-form">
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-customer">
                            Customer<span className="text-danger">*</span> <Button style={btn_add} color="primary ni ni-fat-add" onClick={this.addcustomer} type="button">
                            </Button>
                          </label>
                          <select className="form-control form-control-alternative" id="input-customer" onChange={this.onChangeCustomer}>
                            {this.state.customerDropdown.map((customer, idx) => (
                              <option value={customer.c_id} key={idx}>{customer.c_name}</option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-orderdate">
                            Order Date<span className="text-danger">*</span>
                          </label>
                          <Input ref="order_date" className="form-control-alternative" id="input-orderdate" placeholder="Date" type="Date" onChange={this.onChangeOrderDate} value={this.state.o_date} />
                          <span style={{ color: "red", fontSize: "13px" }}>{this.state.errors["order_date"]}</span>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-brand">
                            Brand Name<span className="text-danger">*</span>
                          </label>
                          <select className="form-control form-control-alternative" id="input-brand" onChange={this.onChangeBrand}>
                            {brands.map((brand, idx) => (
                              <option value={brand.brand_id} key={idx}>{brand.brand_name}</option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-product_type">
                            Product Type<span className="text-danger">*</span>
                          </label>
                          <select className="form-control form-control-alternative" id="input-product_type" onChange={this.onChangeProductType}>
                            {product_types.map((product_type, idx) => (
                              <option value={product_type.product_type_id} key={idx}>{product_type.product_type_name}</option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-product">
                            Product <Button style={btn_add} color="primary ni ni-fat-add" onClick={this.appendProduct} type="button">
                            </Button>
                          </label>
                          <select className="form-control form-control-alternative" id="input-product" onChange={this.onChangeOrderProduct}>
                            {products.map((product, idx) => (
                              <option value={product.id} key={idx}>{product.name}</option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-paymentmthd">
                            Payment Method<span className="text-danger">*</span>
                          </label>
                          <select className="form-control form-control-alternative" id="input-paymentmthd" onChange={this.onChangePaymentMethod}>
                            <option value="COD">COD</option>
                            <option value="Credit">Credit</option>
                            <option value="Advance">Advance</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    {show_alert_qty && (
                      <Alert className="alert-warning">
                        <button type="button" className="close" onClick={this.onCloseAlertQty}>
                          <span aria-hidden="true">×</span>
                        </button>
                        <strong>Warning!</strong> {this.state.alert_msg}
                      </Alert>
                    )}
                    {opened && (
                      <Row>
                        <div className="col">
                          {this.state.shareholders.map((shareholder, idx) => (
                            <Card className="shadow card mb-4 p-1" key={idx}>
                              <div className="row p-2">
                                <div className="col-10">
                                  <label className="mb-0 product_lbl">{shareholder.p_name}</label>
                                </div>
                                <div className="col-2 del_section pl-1">
                                  <button type="button" className="btn_trash fa fa-trash text-danger" onClick={this.removeProduct(idx)}></button>
                                </div>
                              </div>
                              <hr className="product_hr"></hr>
                              <div className="row p-2">
                                <div className="col-5 qty_col">
                                  <div className="mb-1">
                                    <span className="product_lbl">Quantity</span>
                                  </div>
                                  <div>
                                    <input type="hidden" value={shareholder.p_id} />
                                    <input type="number" className="form-control qtytxt" placeholder={`Product #${idx + 1} Qty`} value={shareholder.o_qty} onChange={this.handleProductQtyChange(idx)} id="input-quantity"></input>
                                  </div>
                                </div>
                                <div className="col-7 text-right amt_div amt_col">
                                  <div className="mb-1">
                                    <span className="product_lbl">Price : </span>
                                    <span className="text-danger">{shareholder.p_price} K</span>
                                  </div>
                                  <div className="pt-1">
                                    <span className="product_lbl">Amount : </span>
                                    <span className="text-danger">{shareholder.o_amount} K</span>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          ))}
                          {show_total && (
                          <Card className="shadow card mb-4 p-1 tot_section">
                            <div className="row p-2">
                              <div className="col-6">
                                <span className="product_lbl">Sub Total : </span>
                              </div>
                              <div className="col-6 text-right">
                                <span className="text-danger">{this.state.o_sub_total} K</span>
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="col-6">
                                <span className="product_lbl">Discount : </span>
                              </div>
                              <div className="col-6 text-right">
                                <Input type="number" className="form-control distxt" id="input-quantity" type="number" value={this.state.o_discount} onChange={this.onDiscountChange} />
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="col-7">
                                <span className="product_lbl">Total Amount : </span>
                              </div>
                              <div className="col-5 text-right pl-0">
                                <span className="text-danger">{this.state.o_total} K</span>
                              </div>
                            </div>
                          </Card>
                          )}
                        </div>
                      </Row>
                    )}
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-remark">Remark</label>
                          <Input className="form-control-alternative" id="input-remark" placeholder="Remark" type="text" onChange={this.onChangeRemark} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" className="text-center">
                        <Button className="my-4" color="danger" type="button" onClick={this.handleSubmit}>
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Container>
        <Modal isOpen={this.state.modal}>
          <Form>
            <div class="modal-header">
              <h3 className="modal-title">Add Customer</h3>
              {/* <button onClick={this.addcustomer} className="fa fa-times"></button> */}
            </div>
            <div className="modal-body">
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-fname">
                      First Name<span className="text-danger">*</span>
                    </label>
                    <input style={styles.input} value={this.state.c_fname} onChange={this.onChangeCfname} autoComplete="off" type="text" className="form-control-alternative" placeholder="First Name" id="input-fname" />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-lname">
                      Last Name<span className="text-danger">*</span>
                    </label>
                    <input style={styles.input} value={this.state.c_lname} onChange={this.onChangeClname} autoComplete="off" type="text" className="form-control-alternative" placeholder="Last Name" id="input-lname" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-email">
                      Email<span className="text-danger">*</span>
                    </label>
                    <input style={styles.input} value={this.state.c_email} onChange={this.onChangeCemail} autoComplete="off" type="email" className="form-control-alternative" placeholder="Email" id="input-email" />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-mob">
                      Mobile Number<span className="text-danger">*</span>
                    </label>
                    <input style={styles.input} value={this.state.c_mobile} onChange={this.onChangeCmobile} autoComplete="off" type="number" className="form-control-alternative" placeholder="Mobile Number" id="input-mob" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-address">
                      Address<span className="text-danger">*</span>
                    </label>
                    <input style={styles.input} value={this.state.c_address} onChange={this.onChangeCaddress} autoComplete="off" type="text" className="form-control-alternative" placeholder="Address" id="input-address" />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              {/* <input type="submit" value="Submit" color="primary" className="btn" /> */}
              <Button className="bg-orange border-0" type="button" style={{ color: "white" }} onClick={this.savecustomer}>Add</Button>
              <Button color="secondary" onClick={this.addcustomer}>Close</Button>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}

const styles = {
  input: {
    padding: 10,
  },
};

export default Place_order;
