import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,Badge
} from "reactstrap";
import axios from 'axios';
import { server_config } from 'const';
import Pagination from "react-js-pagination";

class Order extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage["appState"] ? JSON.parse(localStorage["appState"]).user.auth_token : "",
      u_id: localStorage["appState"] ? JSON.parse(localStorage["appState"]).user.id : "0",
      isLoaded: false,
      t_order: 0,
      disp_order: 0,
      pending_order: '',
      orders: [],
      activePage: 1
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.getOrderData(1);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  getOrderData(pageNumber) {
    const apiData = {
      page: pageNumber
    };
    axios.post(server_config.API_BASE_URL+"order_list", apiData, { headers: { "Authorization": "Bearer " + this.state.token } })
        .then(result => {
          this.setState({
            isLoaded: true,
            orders: result.data.order_list.data,
            t_order: result.data.order_list.total,
            disp_order: result.data.dispach_order,
            pending_order: result.data.pending_order
          });
        }).catch(function (error) {
            alert(error);
        });
  }

  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber,isLoaded:false});
    this.getOrderData(pageNumber);
  }

  render() {
    const { isLoaded, orders, t_order, disp_order, pending_order } = this.state;
    if (!isLoaded) {
      return (
        <div className="header bg_headercss pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="6" xl="6">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>Loading...</CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      );
    } else {
      return (
          <>
            <div className="header bg_headercss pb-8 pt-5 pt-md-8">
              <Container fluid>
                <div className="header-body">
                  {/* Card stats */}
                  <Row>
                    <div className="order_card">
                      <Card className="card-stats mb-4 mb-xl-0 m-1 p-1">
                        <span className="h2 font-weight-bold mb-0 text-danger">
                          {t_order}
                        </span>
                        <CardTitle
                          tag="h5"
                          className="mb-0 order_card_title"
                        >
                          Total Order
                            </CardTitle>
                      </Card>
                    </div>
                    <div className="order_card">
                      <Card className="card-stats mb-4 mb-xl-0 m-1 p-1">
                        <span className="h2 font-weight-bold mb-0 text-info">
                          {pending_order}
                        </span>
                        <CardTitle
                          tag="h5"
                          className="mb-0 order_card_title"
                        >
                          Pending Order
                            </CardTitle>
                      </Card>
                    </div>
                    <div className="order_card">
                      <Card className="card-stats mb-4 mb-xl-0 card m-1 p-1">
                        <span className="h2 font-weight-bold mb-0 text-success">
                          {disp_order}
                        </span>
                        <CardTitle
                          tag="h5"
                          className="mb-0 order_card_title"
                        >
                          Complete Order
                            </CardTitle>
                      </Card>
                    </div>
                  </Row>
  
                </div>
              </Container>
            </div>
            {/* Page content */}
            {(this.state.t_order > 0) &&
              <Container className="mt--7" style={{ marginBottom: 100 }} fluid>
                <Row>
                  <Col lg="12">
                    {orders.map(order => (
                      <div className="card shadow mb-3" key={order.order_id}>
                        <div className="card-body stock_card_body">
                          <div className="stock_card_data h3 font-weight-bold mb-0">
                            <span className="customer_name">{order.customer_name} <span className="h5"> ({order.order_date})</span></span>
                            {((order.is_dispatch === 1) ? <Badge color="info" className="my-badge" pill>Dispatched</Badge> : <Badge color="warning" className="my-badge" pill>Pending</Badge>)}
                          </div>
                          <hr className="stock_card_divider"></hr>
                          <div className="stock_card_data">
                            <span>Amount : </span>
                            <span className="stock_card_metadata"> {order.order_amount}</span>
                            <span className="right_content">
                              <span className="pl-3">Payment Mode : </span>
                              <span className="stock_card_metadata">{order.payment_method}</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Col>
                  {(this.state.t_order > 10) &&
                    <Col lg="12" className="text-center">
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.t_order}
                            pageRangeDisplayed={3}
                            onChange={this.handlePageChange.bind(this)}
                          />                
                    </Col>
                  }              
                </Row>
              </Container>
            }
          </>
        );   
    }
  }
}

export default Order;
