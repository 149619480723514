import React from "react";
import { render } from "react-dom";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.jsx";
import Login from "./views/examples/Login.jsx";
import { server_config } from './const';

import axios from "axios";
import $ from "jquery";

class App extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      user: {}
    };
  }
  _loginUser = (email, password) => {
    $("#login-form button").attr("disabled", "disabled").html('<i className="fa fa-spinner fa-spin fa-1x fa-fw"></i><span className="sr-only">Loading...</span>');

    var formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    axios.post(server_config.API_BASE_URL+"login", formData)
      .then(response => {
        return response;
      }).then(json => {
        if (json.status===200 && json.data.data.status===1) {
          const { name, id, email, auth_token } = json.data.data;

          let userData = {
            name,
            id,
            email,
            auth_token,
            timestamp: new Date().toString()
          };
          let appState = {
            isLoggedIn: true,
            user: userData
          };
          // save app state with user date in local storage
          localStorage["appState"] = JSON.stringify(appState);
          this.props.history.push("/admin/dashboard");          
        } else{
          $(".alert").remove();
          $("#login-form").prepend('<div class="alert alert-danger alert-dismissible show alert_card">Please Enter Valid Password.</div>');
        } 

        $("#login-form button").removeAttr("disabled").html("Login");
      }).catch(error => {
        alert(error);
        $("#login-form button").removeAttr("disabled").html("Login");
      });
  }; 

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      let state = localStorage["appState"];
      if (state) {
        let AppState = JSON.parse(state);
        this.setState({ isLoggedIn: AppState.isLoggedIn, user: AppState });
      }
   }    
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    let is_login=(localStorage["appState"])?JSON.parse(localStorage["appState"]).isLoggedIn:false;
    if (!is_login && this.props.location.pathname !== "/auth/login") {
      this.props.history.push("/auth/login");
    } else if (is_login && this.props.location.pathname === "/auth/login") {
      this.props.history.push("/admin/dashboard");
    }  

    return (
      <Switch data="data" id="main">
        <Route
            exact
            path="/"
            render={props => (
              <AdminLayout
                {...props}
                logoutUser={this._logoutUser}
                user={this.state.user}
              />
            )}
          />
          <Route path="/auth/login" render={props => <Login {...props} loginUser={this._loginUser} />} />
          
          <Route path="/admin" render={props => <AdminLayout {...props} />} />

          <Route path="/" render={props => <AdminLayout {...props} />} />
        
      </Switch>
    );
  }
}

const AppContainer = withRouter(props => <App {...props} />);

render(
  <BrowserRouter>
    <AppContainer />
  </BrowserRouter>,

  document.getElementById("root")
);