/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  Container,
  Row, CardBody, CardTitle, Col
} from "reactstrap";
// core components
import DashHeader from "components/Headers/dash_header.jsx";
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.onStock = this.onStock.bind(this);
    this.onPlaceorder = this.onPlaceorder.bind(this);

  }
  onStock() {
    let path = `./stock`;
    this.props.history.push(path);
  }
  onPlaceorder() {
    let path = `./Place_order`;
    this.props.history.push(path);
  }
  render() {
    return (
      <>
        <DashHeader />
        {/* Page content */}
        <Container className="" fluid>
          <Row>
            <Col lg="6">
              <Card className="card-stats mb-4 m-3 card">
                <CardBody className="dash_card" to="./stock" onClick={this.onStock}>
                  <Row>
                    <Col className="col-lg-6 text-center pt-2">
                      <div className="icon icon-shape bg-danger text-white rounded-circle shadow" to="./stock" onClick={this.onStock}>
                        <i className="ni ni-cart" />
                      </div>
                    </Col>
                    <div className="col-lg-6 text-center">
                      <CardTitle
                        tag="h5"
                        className="h2 font-weight-bold pt-3 mb-1"
                      >
                        View Stock
                          </CardTitle>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card className="card-stats mb-4 m-3 card">
                <CardBody className="dash_card" to="./Place_order" onClick={this.onPlaceorder}>
                  <Row>
                    <Col className="col-lg-6 text-center pt-2">
                      <div className="icon icon-shape bg-default text-white rounded-circle shadow" to="./Place_order" onClick={this.onPlaceorder}>
                        <i className="ni ni-briefcase-24" />
                      </div>
                    </Col>
                    <div className="col-lg-6 text-center">
                      <CardTitle
                        tag="h5"
                        className="h2 font-weight-bold pt-3 mb-1"
                      >
                        Place Order
                          </CardTitle>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Dashboard;
