import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col
} from "reactstrap";

const Login = ({ history, loginUser = f => f }) => {
  let _email, _password;
  const handleLogin = e => {
    e.preventDefault();
   loginUser(_email.value, _password.value);
  };
  return (
 <>
 <div className="mt-8 pb-5 container"><div className="justify-content-center row">
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent login_card text-center">
            <p className="login_title text-danger">Trans Global</p>            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
             <form id="login-form" action="" onSubmit={handleLogin} method="post">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <input style={styles.input} ref={input => (_email = input)} autoComplete="off" id="email-input" name="email" type="text" className="center-block" placeholder="email" />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <input style={styles.input} ref={input => (_password = input)} autoComplete="off" id="password-input" name="password" type="password" className="center-block" placeholder="password" />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
	 	<Button type="submit" className="my-4" id="email-login-btn" color="primary btn-block">
          		Login
        	</Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
        </div></div>
    
</>
  );
};

const styles = {
  input: {
    border: "0",
    padding: 12,
    width: "83.8%"
  },
};

export default Login;

