import React from "react";

// reactstrap components
import {
  Col
} from "reactstrap";
class page_not_found extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.GoToDashboard = this.GoToDashboard.bind(this);
  }
  GoToDashboard() {
    this.props.history.push('./admin/Dashboard');
  }

  render() {
    return (
      <>
      <div className="mt-8 pb-5 container">
          <div className="justify-content-center row">
            <Col lg="5" md="7">
                <div className="text-center">
                  <img
                    alt="..."
                    className="page_not_img"
                    src={require("assets/img/page_not_found.png")}
                  />
                      <h3 className="mt-2">Page Not Found</h3>
                      <button type="button" class="btn-icon btn btn-danger go_btn mt-1" onClick={this.GoToDashboard}><span class="btn-inner--icon go_to_icon"><i class="ni ni-bold-left"></i></span><span class="btn-inner--text">Go To Dashboard</span></button>
                  </div>
            </Col>
          </div>
        </div>
      </>
    );
  }
}

export default page_not_found;
