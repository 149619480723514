import React from "react";

import {
  Card,
  Container,
  Row,
  CardBody,
  Col
} from "reactstrap";
import axios from 'axios';
import { server_config } from 'const';
import Pagination from "react-js-pagination";

class Stock extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage["appState"] ? JSON.parse(localStorage["appState"]).user.auth_token : "",
      u_id: localStorage["appState"] ? JSON.parse(localStorage["appState"]).user.id : "0",
      error: null,
      isLoaded: false,
      t_stock: 0,
      items: [],
      activePage: 1
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.getStockData(1);
    }
  }

  getStockData(pageNumber) {
    const apiData = {
      page: pageNumber
    };
    axios.post(server_config.API_BASE_URL+"stock_list", apiData, { headers: { "Authorization": "Bearer " + this.state.token } })
      .then(result => {
        this.setState({
          isLoaded: true,
          items: result.data.data,
          t_stock: result.data.total
        });
      }).catch(function (error) {
        alert(error);
      });
  }

  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber,isLoaded:false});
    this.getStockData(pageNumber);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const { error, isLoaded, items, t_stock } = this.state;
    if (error) {
      return (
        <div className="header bg_headercss pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="12">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>Error: {error.message}</CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      );
    } else if (!isLoaded) {
      return (
        <div className="header bg_headercss pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col lg="12">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>Loading...</CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      );
    } else {
      return (
        <>
          <div className="header bg_headercss pb-7 pt-4 pt-md-8">
            <Container fluid>
              <div className="header-body">
                {/* Card stats */}
                <Row>
                  <Col lg="12" xl="12" className="text-right">
                    <h2><span>Total Product : </span> <span className="stock_header">{t_stock}</span></h2>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          {/* Page content */}
          <Container className="mt--7" fluid>
            {/* demo table starts */}
            <Row>
              <div className="col-12">
                {items.map((item,idx) => (
                  <Card className="shadow mb-3" key={idx}>
                    <div className="card-body stock_card_body">
                      <div className="stock_card_data h3 font-weight-bold mb-0">{item.name}
                      </div>
                      <hr className="stock_card_divider"></hr>
                      <div className="stock_card_data">
                        <span>Available Quantity : </span>
                        <span className="stock_card_metadata"> {item.quantity}</span>
                        <span className="right_content">
                          <span className="pl-5">Price : </span>
                          <span className="stock_card_metadata"> {item.sale_kwacha_price} K</span>
                        </span>
                      </div>
                    </div>
                  </Card>
                ))}                
              </div>
              {(this.state.t_stock > 10) &&
              <Col lg="12" className="text-center">
                    <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.t_stock}
                        pageRangeDisplayed={3}
                        onChange={this.handlePageChange.bind(this)}
                      />                
              </Col>
              }
            </Row>
          </Container>
        </>
      );
    }
  }
}

export default Stock;
